.borderless {
  border: none;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
}

#app {
  min-width: 1199px;
}

/* 
 * The above style on #app is preventing responsive behaviour
 * A longer term solution needs to be implemented to remove this global style
 */
#app:has(#enroll) {
  min-width: unset !important;
}

#app:has(#add-library-item-page) {
  min-width: 768px !important;
}

#app:has(#library-items-index) {
  min-width: 768px !important;
}
